export const GET_QUESTIONS = 'GET_QUESTIONS'
export const SET_ANSWER = 'SET_ANSWER'
export const SET_VERSION = 'SET_VERSION'
export const SET_ANSWERS = 'SET_ANSWERS'
export const SET_TRANSITION = 'SET_TRANSITION'
export const POST_QUIZ_DATA_SUCCESS = 'POST_QUIZ_DATA_SUCCESS'
export const POST_QUIZ_DATA_ERROR = 'POST_QUIZ_DATA_ERROR'
export const SET_VALIDATION_ERROR = 'SET_VALIDATION_ERROR'
export const GET_INGREDIENTS = 'GET_INGREDIENTS'
// export const DECREMENT_INGREDIENTS = 'DECREMENT_INGREDIENTS'
export const GET_CLINICAL_RESULTS = 'GET_CLINICAL_RESULTS'
export const SET_FOLLOWUP_QUESTIONS = 'SET_FOLLOWUP_QUESTIONS'
export const SET_FOLLOWUP_ANSWERS = 'SET_FOLLOWUP_ANSWERS'

export const GET_USER_IN_PROGRESS = 'GET_USER_IN_PROGRESS'
export const GET_USER_ERROR = 'GET_USER_ERROR'

export const CHECK_USER_AUTH = 'CHECK_USER_AUTH'
export const CHECK_USER_AUTH_ERROR = 'CHECK_USER_AUTH_ERROR'
export const SET_PATH_BEFORE_LOGIN = 'SET_PATH_BEFORE_LOGIN'

export const SET_APP_MESSAGE = 'SET_APP_MESSAGE'
export const SET_APP_ABOVE_HEADER_MESSAGE = 'SET_APP_ABOVE_HEADER_MESSAGE'
export const SET_APP_IS_WELCOME_BACK_PAGE = 'SET_APP_IS_WELCOME_BACK_PAGE'

export const SET_EXPERIMENT_VARIANT = 'SET_EXPERIMENT_VARIANT'
export const CLEAR_APP_VARIANTS = 'CLEAR_APP_VARIANTS'

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION'
export const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const SET_PROVEN_HEADER_MODE = 'SET_PROVEN_HEADER_MODE'
export const SET_APP_EDITION = 'SET_APP_EDITION'
export const SET_SUBSCRIPTION_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE =
  'SET_SUBSCRIPTION_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE'
export const SET_ONE_TIME_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE =
  'SET_ONE_TIME_OPTION_FOR_NEXT_ACTION_ON_CURRENT_PRODUCT_PAGE'

// ACCOUNT ACTIONS
export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA'
export const CLEAR_ACCOUNT_DATA = 'CLEAR_ACCOUNT_DATA'
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'
export const SET_ACCOUNT_SHIPPING_ADDRESS = 'SET_ACCOUNT_SHIPPING_ADDRESS'

export const SET_ACCOUNT_BILLING_ADDRESS = 'SET_ACCOUNT_BILLING_ADDRESS'
export const CLEAR_ACCOUNT_BILLING_ADDRESS = 'CLEAR_ACCOUNT_BILLING_ADDRESS'

export const SET_ACCOUNT_PERSONAL_INFO = 'SET_ACCOUNT_PERSONAL_INFO'
export const SET_ACCOUNT_PAYMENT_METHOD = 'SET_ACCOUNT_PAYMENT_METHOD'

export const SET_ACCOUNT_COMPLETE_QUIZ = 'SET_ACCOUNT_COMPLETE_QUIZ'

export const UNSUBSCRIBE_START = 'UNSUBSCRIBE_START'
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS'

export const SET_ACCOUNT_ERROR = 'SET_ACCOUNT_ERROR'
export const CLEAR_ACCOUNT_ERROR = 'CLEAR_ACCOUNT_ERROR'
export const GET_RESULTS = 'GET_RESULTS'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_REFERRAL_DASHBOARD = 'GET_REFERRAL_DASHBOARD'
export const GET_ORDER = 'GET_ORDER'
export const GET_ORDER_HISTORY = 'GET_ORDER_HISTORY'
export const GET_SUBSCRIPTION_ORDERS_IN_DRAFT_SUCCESS = 'GET_SUBSCRIPTION_ORDERS_IN_DRAFT_SUCCESS'
export const GET_SUBSCRIPTION_ORDERS_IN_DRAFT_ERROR = 'GET_SUBSCRIPTION_ORDERS_IN_DRAFT_ERROR'
export const GET_SUBSCRIPTION_ORDERS_IN_PENDING_SUCCESS =
  'GET_SUBSCRIPTION_ORDERS_IN_PENDING_SUCCESS'
export const GET_SUBSCRIPTION_ORDERS_IN_PENDING_ERROR = 'GET_SUBSCRIPTION_ORDERS_IN_PENDING_ERROR'
export const GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_IN_PROGRESS =
  'GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_IN_PROGRESS'
export const GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_SUCCESS =
  'GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_SUCCESS'
export const GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_ERROR =
  'GET_SUBSCRIPTION_ORDERS_IN_DRAFT_OR_AWAITING_PAYMENT_ERROR'
export const SET_LOYALTY_PROGRAM = 'SET_LOYALTY_PROGRAM'
export const SET_ACCOUNT_INFO = 'SET_ACCOUNT_INFO'
export const OPEN_ORDER_TOTAL = 'OPEN_ORDER_TOTAL'
export const CLOSE_ORDER_TOTAL = 'CLOSE_ORDER_TOTAL'
export const GET_EYE_CREAM_QUIZ_RESULTS = 'GET_EYE_CREAM_QUIZ_RESULTS'
export const GET_SERUM_RESULTS = 'GET_SERUM_RESULTS'
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS'

//export const GET_ACCOUNT_PREFERENCE = 'GET_ACCOUNT_PREFERENCE';
export const SET_ACCOUNT_PREFERENCE = 'SET_ACCOUNT_PREFERENCE'

//export const GET_ACCOUNT_FEEDBACK = 'GET_ACCOUNT_FEEDBACK';
export const SET_ACCOUNT_FEEDBACK = 'SET_ACCOUNT_FEEDBACK'
export const SET_UPCOMING_SETS = 'SET_UPCOMING_SETS'
export const SET_MOST_RECENT_QUIZ_ID = 'SET_MOST_RECENT_QUIZ_ID'
export const SET_LATEST_SETS = 'SET_LATEST_SETS'
export const SET_SEPHORA_STORE_ID = 'SET_SEPHORA_STORE_ID'
export const SET_SEPHORA_ADVISOR_NAME = 'SET_SEPHORA_ADVISOR_NAME'
export const SET_LEAD_EMAIL = 'SET_LEAD_EMAIL'

export const CLOSE_ORDER_REQUEST_IN_PROGRESS = 'CLOSE_ORDER_REQUEST_IN_PROGRESS'
export const CLOSE_ORDER_REQUEST_SUCCESS = 'CLOSE_ORDER_REQUEST_SUCCESS'
export const CLOSE_ORDER_REQUEST_ERROR = 'CLOSE_ORDER_REQUEST_ERROR'
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS'
// END OF ACCOUNT ACTIONS

export const SET_STATUS = 'SET_STATUS'
export const GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS'
export const GET_INVOICE_DETAILS_IN_PROGRESS = 'GET_INVOICE_DETAILS_IN_PROGRESS'
export const GET_INVOICE_DETAILS_SUCCESS = 'GET_INVOICE_DETAILS_SUCCESS'
export const GET_INVOICE_DETAILS_ERROR = 'GET_INVOICE_DETAILS_ERROR'

export const SET_COUPON = 'SET_COUPON'
export const SET_CHECKOUT_WARNING = 'SET_CHECKOUT_WARNING'

export const SET_CJEVENT = 'SET_CJEVENT'
export const SET_MOCK_CART_ID = 'SET_MOCK_CART_ID'
export const SET_MOCK_ORDER_ID = 'SET_MOCK_ORDER_ID'
export const SET_MOCK_CHECKOUT_ID = 'SET_MOCK_CHECKOUT_ID'

// AUTH ACTION TYPES
export const AUTH_USER = 'AUTH_USER'
export const AUTH_ERROR = 'AUTH_ERROR'
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'
export const CLEAR_AUTH = 'CLEAR_AUTH'
export const SET_LOGOUT_USER_ATTEMPT = 'SET_LOGOUT_USER_ATTEMPT'
export const CLEAR_LOGOUT_USER_ATTEMPT = 'CLEAR_LOGOUT_USER_ATTEMPT'

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_IN_PROGRESS = 'FORGOT_PASSWORD_IN_PROGRESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'

export const USER_NOT_FOUND = 'USER_NOT_FOUND'
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED'

export const PASSWORD_RESET_REQUIRED = 'PASSWORD_RESET_REQUIRED'

export const SIGNIN_PASSWORD_AUTH_VERFICIATION = 'SIGNIN_PASSWORD_AUTH_VERFICIATION'
export const SIGNIN_PASSWORD_SUCCESS = 'SIGNIN_PASSWORD_SUCCESS'
export const SIGNIN_PASSWORD_FAIL = 'SIGNIN_PASSWORD_FAIL'
export const SIGNIN_USER_NOT_FOUND = 'SIGNIN_USER_NOT_FOUND'

export const SIGNUP_PASSWORD_AUTH_VERFICIATION = 'SIGNUP_PASSWORD_AUTH_VERFICIATION'
export const SIGNUP_PASSWORD_SUCCESS = 'SIGNUP_PASSWORD_SUCCESS'
export const SIGNUP_PASSWORD_FAIL = 'SIGNUP_PASSWORD_FAIL'

export const FIRST_LOGIN_AFTER_COMPLETE_QUIZ = 'FIRST_LOGIN_AFTER_COMPLETE_QUIZ'

// END OF AUTH ACTION TYPES

// APP ACTION TYPES
export const CLEAR_APP_STATE = 'CLEAR_APP_STATE'
export const OPEN_MOBILE_MENU = 'OPEN_MOBILE_MENU'
export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU'

// END OF APP ACTION TYPES

// REQUEST ACTION TYPES
export const REQUEST_SENT = 'REQUEST_SENT'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_FAIL = 'REQUEST_FAIL'
// END OF REQUEST ACTION TYPES

// PASSWORDLESS_AUTH ACTION TYPES
export const SUCCESS_START_PASSWORDLESS_AUTH = 'SUCCESS_START_PASSWORDLESS_AUTH'
export const FAIL_START_PASSWORDLESS_AUTH = 'FAIL_START_PASSWORDLESS_AUTH'
export const SUCCESS_PASSWORDLESS_AUTH = 'SUCCESS_PASSWORDLESS_AUTH'
export const FAIL_PASSWORDLESS_AUTH = 'FAIL_PASSWORDLESS_AUTH'
export const CLEAR_PASSWORDLESS_AUTH = 'CLEAR_PASSWORDLESS_AUTH'

//not used -----
export const START_PASSWORDLESS_AUTH_VERFICIATION = 'START_PASSWORDLESS_AUTH_VERFICIATION'
export const FAIL_PASSWORDLESS_AUTH_VERFICIATION = 'FAIL_PASSWORDLESS_AUTH_VERFICIATION'
// END OF PASSWORDLESS_AUTH ACTION TYPES

// USER QUIZ ACTION TYPES
export const SET_USER_QUIZ_DATA = 'SET_USER_QUIZ_DATA'
export const CREATE_USER_QUIZ_DATA = 'CREATE_USER_QUIZ_DATA'
export const SUCCESS_USER_QUIZ_DATA = 'SUCCESS_USER_QUIZ_DATA'
export const FAIL_USER_QUIZ_DATA = 'FAIL_USER_QUIZ_DATA'
export const SET_REFERRER = 'SET_REFERRER'
export const SET_QUIZ_PENDING_STATUS = 'SET_QUIZ_PENDING_STATUS'
export const CLEAR_QUIZ_ANSWERS = 'CLEAR_QUIZ_ANSWERS'
export const COMPLETED_QUIZ = 'COMPLETED_QUIZ'
export const SET_AUTOCOMPLETE_INPUT_VALUE = 'SET_AUTOCOMPLETE_INPUT_VALUE'
// END OF USER QUIZ ACTION TYPES

// USER PRESALE ACTION TYPES
export const SET_USER_PRESALE_DATA = 'SET_USER_PRESALE_DATA'
export const CREATE_USER_PRESALE_DATA = 'CREATE_USER_PRESALE_DATA'
export const SUCCESS_USER_PRESALE_DATA = 'SUCCESS_USER_PRESALE_DATA'
export const FAIL_USER_PRESALE_DATA = 'FAIL_USER_PRESALE_DATA'
// END OF USER PRESALE ACTION TYPES

// CHECKOUT ACTION TYPES
export const SUCCESS_SUBSCRIBE_PRODUCT = 'SUCCESS_SUBSCRIBE_PRODUCT'
export const FAIL_SUBSCRIBE_PRODUCT = 'FAIL_SUBSCRIBE_PRODUCT'
export const START_LOAD_STATES = 'START_LOAD_STATES'
export const SUCCESS_LOAD_STATES = 'SUCCESS_LOAD_STATES'
export const OPEN_CHECKOUT_SLIDE = 'OPEN_CHECKOUT_SLIDE'
export const CLOSE_CHECKOUT_SLIDE = 'CLOSE_CHECKOUT_SLIDE'
export const OPEN_LOGIN_SLIDE = 'OPEN_LOGIN_SLIDE'
export const CLOSE_LOGIN_SLIDE = 'CLOSE_LOGIN_SLIDE'
export const SET_CHECKOUT_STEP = 'SET_CHECKOUT_STEP'
export const SET_HAS_SENT_ORDER_COMPLETED = 'SET_HAS_SENT_ORDER_COMPLETED'
export const OPEN_CHECKOUT_POPUP = 'OPEN_CHECKOUT_POPUP'
export const CLOSE_CHECKOUT_POPUP = 'CLOSE_CHECKOUT_POPUP'
export const SET_SKIP_ACCESSORIES = 'SET_SKIP_ACCESSORIES'
export const SET_ACCESSORIES_PURCHASE_PREVIEW = 'SET_ACCESSORIES_PURCHASE_PREVIEW'
// END OF CHECKOUT ACTION TYPES

// HUBSPOT ACTION TYPES
export const UPDATE_HUBSPOT_CONTACT_SUCCESS = 'UPDATE_HUBSPOT_CONTACT_SUCCESS'
export const UPDATE_HUBSPOT_CONTACT_ERROR = 'UPDATE_HUBSPOT_CONTACT_ERROR'

// CART ACTION TYPES
export const CART_OPEN = 'CART_OPEN'
export const CART_CLOSE = 'CART_CLOSE'
export const CART_INCREASE_QTY = 'CART_INCREASE_QTY'
export const CART_DECREASE_QTY = 'CART_DECREASE_QTY'
export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_UPDATE_ITEM = 'CART_UPDATE_ITEM'
export const CART_CLEAR = 'CART_CLEAR'
export const CART_CHANGE_QTY = 'CART_CHANGE_QTY'
export const CART_ADD_ACCESSORY_ITEM = 'CART_ADD_ACCESSORY_ITEM'
export const CART_REMOVE_ACCESSORY_ITEM = 'CART_REMOVE_ACCESSORY_ITEM'
export const START_CHECKING_COUPON_CODE = 'START_CHECKING_COUPON_CODE'
export const STOP_CHECKING_COUPON_CODE = 'STOP_CHECKING_COUPON_CODE'
export const SET_ORDERED_SUBSCRIBABLE_PRICE_IDS = 'SET_ORDERED_SUBSCRIBABLE_PRICE_IDS'
// END OF CART ACTION TYPES

// PRODUCT ACTION TYPES
export const PRODUCT_SYNC = 'PRODUCT_SYNC'
// END OF PRODUCT ACCTION TYPES

// SUPPORT ACTION TYPES
export const CREATE_GORGIAS_TICKET = 'CREATE_GORGIAS_TICKET'
export const WRITE_YOTPO_REVIEW = 'WRITE_YOTPO_REVIEW'
// END OF SUPPORT ACTION TYPES

// SHOP ACTION TYPES
export const UPDATE_SHOP_SUBSCRIPTION = 'UPDATE_SHOP_SUBSCRIPTION'
// export const ADD_SHOP_PRODUCT_TO_CART = 'ADD_SHOP_PRODUCT_TO_CART'
// export const REMOVE_SHOP_PRODUCT_FROM_CART = 'REMOVE_SHOP_PRODUCT_FROM_CART'
// export const UPDATE_SHOP_PRODUCT_IN_CART = 'UPDATE_SHOP_PRODUCT_IN_CART'
// export const CLEAR_SHOP_CART = 'CLEAR_SHOP_CART'
// export const SET_SHOP_ORDER_PREVIEW = 'SET_SHOP_ORDER_PREVIEW'
export const SET_ACCESSORIES = 'SET_ACCESSORIES,'
// END OF SHOP ACTION TYPES

// admin

export const GET_USER_DATA = 'GET_USER_DATA'
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA'
export const GET_USER_NOTES_DATA = 'GET_USER_NOTES_DATA'
export const GET_ORDER_NOTES_DATA = 'GET_ORDER_NOTES_DATA'
export const REMOVE_ORDER_NOTES_DATA = 'REMOVE_ORDER_NOTES_DATA'
export const GET_SKUS_DATA = 'GET_SKUS_DATA'
export const GET_ORDERS = 'GET_ORDERS'
export const UPDATE_ORDER_VAS = 'UPDATE_ORDER_VAS'
export const SET_USER_BLUEPRINT_CHART = 'SET_USER_BLUEPRINT_CHART'
export const VERIFY_ORDER = 'VERIFY_ORDER'
export const UPDATE_SKUS = 'UPDATE_SKUS'
export const REPLACE_ORDER = 'REPLACE_ORDER'
export const CREATE_ORDER = 'CREATE_ORDER'
export const UPDATE_USER_SHIPPING_ADDRESS = 'UPDATE_USER_SHIPPING_ADDRESS'
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL'
export const UPDATE_ORDER_SHIPPING_ADDRESS = 'UPDATE_ORDER_SHIPPING_ADDRESS'
export const GET_SET_SKU_IN_PROGRESS = 'GET_SET_SKU_IN_PROGRESS'
export const GET_SET_SKU_SUCCESS = 'GET_SET_SKU_SUCCESS'
export const GET_SET_SKU_ERROR = 'GET_SET_SKU_ERROR'
export const CREATE_SET_SKU_IN_PROGRESS = 'CREATE_SET_SKU_IN_PROGRESS'
export const CREATE_SET_SKU_SUCCESS = 'CREATE_SET_SKU_SUCCESS'
export const CREATE_SET_SKU_ERROR = 'CREATE_SET_SKU_ERROR'
export const UPDATE_SET_SKU_IN_PROGRESS = 'UPDATE_SET_SKU_IN_PROGRESS'
export const UPDATE_SET_SKU_SUCCESS = 'UPDATE_SET_SKU_SUCCESS'
export const UPDATE_SET_SKU_ERROR = 'UPDATE_SET_SKU_ERROR'
export const DELETE_SET_SKU_IN_PROGRESS = 'DELETE_SET_SKU_IN_PROGRESS'
export const DELETE_SET_SKU_SUCCESS = 'DELETE_SET_SKU_SUCCESS'
export const DELETE_SET_SKU_ERROR = 'DELETE_SET_SKU_ERROR'

// Butter CMS Action Types
export const GET_TERMS_CONDITOINS_DATA = 'GET_TERMS_CONDITOINS_DATA'
export const SET_USER_SEARCH = 'SET_USER_SEARCH'

// Gift Card
export const APPLY_GIFT_CARD = 'APPLY_GIFT_CARD'

export const SET_INVENTORY = 'SET_INVENTORY'

export const SET_TRANSITION_QUESTION = 'SET_TRANSITION_QUESTION'
export const SET_QUIZ_SUMMARY_REDIRECT_URL = 'SET_QUIZ_SUMMARY_REDIRECT_URL'
